import React from 'react'
import { graphql } from 'gatsby'
import BlogPostChrome from '../../components/BlogPostChrome'
import {
  Avatar,
  Tabs,
  Row,
  Col,
  Spin,
  Tooltip,
  Input,
  Button,
  Divider
} from 'antd'
import axios from 'axios'
import Layout from '../../components/Layouts'
import GANimationScroller from './ganimation-scroller'
import CoverGIF from './images/cover.gif'

export const frontmatter = {
  title: `GANIMATION: Visual Inspection of GAN Training`,
  written: `2021-02-27`,
  updated: `2021-03-02`,
  layoutType: `post`,
  contentType: `blog`,
  path: `/ganimation/`,
  category: `Generative Models`,
  image: `./poster.png`,
  description: `We visually inspect the images generated by GAN. But, with a twist.`
}

const API = axios.create({
  baseURL: 'https://api.sprinkleai.com'
  // baseURL: 'http://192.168.1.105:9060'
})

const TabPane = Tabs.TabPane

function GANimationArticle(props) {
  return (
    <Layout data={props.data} location={props.location}>
      <BlogPostChrome
        {...props.data.javascriptFrontmatter}
        maxWidthOverride={1200}
        backgroundColorOverride="white"
      >
        <article>
          <div style={{ textAlign: 'center', margin: '0 auto' }}>
            <h1>Visual Inspection of GAN Training</h1>
            <p style={{ textAlign: 'center' }}>Desktop Version</p>
            {/* <div style={{maxWidth: 2000, display: 'flex', justifyContent: 'center', alignItems: 'center'}}> */}
            <div
              style={{
                maxWidth: 800,
                textAlign: 'left',
                margin: '10px auto 30px'
              }}
            >
              <p>
                Generative Adversarial Networks<sup>1</sup>, GANs for short, are
                one of the most popular generative models. Generative models
                learn to generate the <b>probability distribution</b> of the
                features they are trying to model. For example, you can use GANs
                to generate anime faces<sup>2</sup>, icons<sup>3</sup>, fonts
                <sup>4</sup>, and even human faces<sup>5</sup>.{' '}
                <b>Synthetic Data Generator</b>, an alternate name if I may.
              </p>

              <p>
                In this post, we are going to inspect the images generated by
                GAN. But, with a twist. Instead of merely displaying the
                generated images, we will run them through the clustering
                algorithm and overlay them over the cluster of real images. The
                idea for this visualisation came from{' '}
                <b>
                  Sharon Zhou's{' '}
                  <a
                    href="https://twitter.com/realSharonZhou/status/1358663763738521600?s=20"
                    target="_blank"
                  >
                    tweet
                  </a>
                </b>
                .
              </p>
              <p style={{ color: 'gray' }}>
                <i>
                  Please note that this post is <b>not mobile friendly</b> yet
                </i>
              </p>

              <Tabs defaultActiveKey="1" tabPosition="top">
                <TabPane tab="Trailer" key="1">
                  <img
                    style={{ textAlign: 'center' }}
                    src={CoverGIF}
                    alt="Training and Clustering of Generator Images"
                  />
                  <p style={{ fontSize: 10, textAlign: 'center' }}>
                    GAN Training and Clustering of Generated Images
                  </p>
                </TabPane>
                <TabPane tab="Full Video" key="2">
                  <div style={{ width: 800, height: 325, textAlign: 'center' }}>
                    <iframe
                      width="560px"
                      height="315px"
                      style={{ height: 315 }}
                      src="https://www.youtube.com/embed/aVmoXUNIVaY"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                </TabPane>
              </Tabs>
            </div>
          </div>
          {/* <GANimationScroller realDigits={props.data.realDigits.childImageSharp.fluid} /> */}
          <GANimationScroller {...props.data} />
          <Divider />
          <Row>
            <Col span={4} className="references-header">
              References:
            </Col>
            <Col span={20} className="references-text">
              <ol>
                <li>
                  Generative Adversarial Networks &nbsp;[
                  <a
                    href="https://arxiv.org/abs/1406.2661"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    LINK
                  </a>
                  ]
                </li>
                <li>
                  Generate Anime Faces &nbsp;[
                  <a
                    href="https://www.gwern.net/Faces"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    LINK
                  </a>
                  ]
                </li>
                <li>
                  Generate Icons using DCGAN &nbsp;[
                  <a href="/dcgan/" target="_blank" rel="noopener noreferrer">
                    LINK
                  </a>
                  ]
                </li>
                <li>
                  Generate Fonts &nbsp;[
                  <a
                    href="https://arxiv.org/abs/2005.07865"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    LINK
                  </a>
                  ]
                </li>
                <li>
                  Generate Human Faces using StyleGAN2 &nbsp;[
                  <a
                    href="https://thispersondoesnotexist.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    LINK
                  </a>
                  ]
                </li>
                <li>
                  MNIST Dataset &nbsp;[
                  <a
                    href="http://yann.lecun.com/exdb/mnist/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    LINK
                  </a>
                  ]
                </li>
                <li>
                  My 2017 introductory post on GANs [
                  <a href="/hello-gan/">BLOG</a>]
                </li>
                <li>
                  GAN Notebook &nbsp;
                  <a
                    href="https://colab.research.google.com/drive/1wzYIpxbwo5_up9Z-_JoD6S8ZDVgJN2mJ"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="https://colab.research.google.com/assets/colab-badge.svg"></img>
                  </a>
                </li>
                <li>
                  PS: Magical should not be taken in literal sense. The network
                  learns through Backpropagation [
                  <a
                    href="https://medium.com/@karpathy/yes-you-should-understand-backprop-e2f06eab496b"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    BLOG
                  </a>
                  ]
                </li>
                <li>
                  DCGAN Experimentation on various datasets (Already ancient as
                  per GAN timeline) [
                  <a href="/dcgan/" target="_blank" rel="noopener noreferrer">
                    BLOG
                  </a>
                  ]
                </li>
              </ol>
            </Col>
          </Row>
        </article>
        {/* <Img sizes={props.data.realDigits.childImageSharp.fluid} /> */}
      </BlogPostChrome>
    </Layout>
  )
}

export default GANimationArticle

// We want to keep this component mostly about the code
//  so we write our explanation with markdown and manually pull it in here.
//  Within the config, we loop all of the markdown and createPages. However,
//  it will ignore any files appended with an _underscore. We can still manually
//  query for it here, and get the transformed html though because remark transforms
//  any markdown based node.
export const pageQuery = graphql`
  query helloworldquery2($slug: String!) {
    markdownRemark(fields: { slug: { eq: "/2021-02-28-ganimation/" } }) {
      html
    }
    javascriptFrontmatter(fields: { slug: { eq: $slug } }) {
      ...JSBlogPost_data
    }
    realDigits: file(relativePath: { regex: "/real_digits.png/" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    ganModel: file(relativePath: { regex: "/GAN-Anim-M.png/" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    realCluster5k: file(relativePath: { regex: "/5k_real.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    trainingBegins: file(relativePath: { regex: "/canvas_8.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    modeCollapseDanger: file(relativePath: { regex: "/canvas_132.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    trainingComplete: file(relativePath: { regex: "/canvas_1400.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    generatedImages: file(relativePath: { regex: "/seed_images.png/" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
  }
`
