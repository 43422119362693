import React, { useState } from 'react'
import injectSheet from 'react-jss'
import Img from 'gatsby-image'
import { Scrollama, Step } from 'react-scrollama'
import ShakyGround from './images/shaky-ground.gif'
import TrainingSuccess from './images/train-success.gif'

const styles = {
  navbar: {
    position: 'fixed',
    display: 'flex',
    top: 0,
    right: 0,
    zIndex: 1,
    '& a': {
      display: 'block',
      fontSize: '20px',
      padding: '20px'
    }
  },
  pageTitle: {
    textAlign: 'center',
    fontSize: 22,
    margin: '90px 0 10px',
    visibility: 'hidden'
  },
  description: {
    maxWidth: 600,
    margin: '10px auto 30px',
    fontSize: 22,
    lineHeight: '28px',
    '& a': {
      color: 'black'
    }
  },
  pageSubtitle: {
    textAlign: 'center',
    fontSize: 22,
    color: '#888'
  },
  graphicContainer: {
    // padding: '4vh 2vw 70vh',
    display: 'flex',
    justifyContent: 'space-between'
  },
  graphic: {
    flexBasis: '70%',
    position: 'sticky',
    width: '100%',
    height: '60vh',
    top: '20vh',
    // backgroundColor: '#aaa',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& p': {
      fontSize: '5rem',
      fontWeight: 700,
      textAlign: 'center',
      color: '#fff'
    }
  },
  scroller: {
    flexBasis: '25%'
  },
  step: {
    margin: '0 auto 3rem auto',
    padding: '180px 0',
    border: '1px solid #333',
    '& p': {
      textAlign: 'center',
      padding: '1rem',
      fontSize: '1.8rem',
      margin: 0
    },
    '&:last-child': {
      marginBottom: 0
    }
  },
  generatorCover: {
    background: '#2AB8CB',
    color: '#fff',
    padding: '3px 7px',
    lineHeight: '2em',
    fontWeight: 500
  },
  discriminatorCover: {
    background: '#F09B4D',
    color: '#fff',
    padding: '3px 7px',
    lineHeight: '2em',
    fontWeight: 500
  },
  generatorTitle: {
    color: '#2AB8CB',
    lineHeight: '2em',
    fontWeight: 700
  },
  discriminatorTitle: {
    color: '#F09B4D',
    lineHeight: '2em',
    fontWeight: 600
  }
}

const COLORS = ['blue', 'salmon', 'green', 'purple', 'yellow']

const stickImages = ['']

const TextBlock1 = () => {
  return (
    <div style={{ height: '200px', color: 'black' }}>
      <h3>Dataset</h3>
      <p>
        We use MNIST<sup>6</sup> dataset of handwritten digits.Our GAN will
        learn to generate these digits. You can read more about the dataset{' '}
        <a href="http://yann.lecun.com/exdb/mnist/">here</a>.
      </p>
      <p>A snapshot of MNIST digits ➡</p>
    </div>
  )
}

const TextBlock2 = () => {
  return (
    <div style={{ height: '250px', color: 'black' }}>
      <h3>Cluster MNIST Digits</h3>
      <p>
        We run 60k handwritten digits from the MNIST dataset through the{' '}
        <b>clustering algorithm</b> and visualise its output in 2D. We see the
        nice and clear cluster emerge for most images.
      </p>
      <ul>
        <li>The cluster of digits 4, 7, and 9 are close to each other</li>
        <li>Similarly, the clusters of 3, 5, and 8 are quite close</li>
        <li>0, 1, 2, and 6 are clear and separated </li>
      </ul>
      <p>Next, We are going to train our network to generate digits.</p>
    </div>
  )
}

const TextBlock3 = (props) => {
  const { classes } = props
  return (
    <div style={{ height: '500px', color: 'black' }}>
      <h3>Model Architecture</h3>
      <p>
        Generative Adversarial Network consist of two neural networks: the{' '}
        <span className={classes.generatorCover}>Generator network</span> and
        the{' '}
        <span className={classes.discriminatorCover}>
          Discriminator network
        </span>
      </p>
      <p>
        The Generator's training objective is to produce a real-looking
        handwritten digit, which is (almost) indistinguishable from the real
        dataset.
      </p>
      <p>
        The goal of Discriminator is to distinguish the fake images from the
        real ones clearly
      </p>
      <ul>
        <li>
          <span className={classes.generatorCover}>Fake</span> - the images
          generated by the Generator
        </li>
        <li>
          <span className={classes.discriminatorCover}>Real</span> - the real
          handwritten digits from the dataset
        </li>
      </ul>
      <p>
        The model and training approach are very similar to my{' '}
        <a href="/hello-gan/">post</a>
        <sup>7</sup> on GAN.
        <i>
          The post also includes the Colab Notebook<sup>8</sup> for you to play
          around
        </i>
        .
      </p>
    </div>
  )
}

const TextBlock4 = (props) => {
  const { classes } = props
  return (
    <div style={{ height: '400px', color: 'black' }}>
      <h3>Training Begins</h3>
      <p>
        In the beginning, the Generator is quite bad at faking the images,
        whereas the Discriminator is quite good at separating real handwritten
        digits from the fake ones.
      </p>
      <p>
        These images look like random noise, which is what they are. After all,
        the Generator takes random noise sampled from Gaussian distribution as
        its input. The discriminator at this point is very confident about its
        decision.
      </p>
      <p>
        The clusters are still the same as previous plot. Additionally, We are
        now running the generated images through same clustering algorithm and
        plotting alongside the real ones in 2D.
        <ul>
          <li>
            <span className={classes.discriminatorCover}>Real</span> - the real
            handwritten digits from the dataset
          </li>
          <li>
            <span className={classes.generatorCover}>Fake</span> - the images
            generated by the Generator
          </li>
        </ul>
      </p>
    </div>
  )
}

const TextBlock5 = () => {
  return (
    <div style={{ height: '300px', color: 'black' }}>
      <h3>Shaky Ground</h3>
      <p>
        After several failed attempts, something magical<sup>6</sup> happens.
        The Generator begins to make a somewhat crude representation of the
        digits.
      </p>
      <p>
        At this point, there is a real danger where Generator can get stuck in
        local minima and collapse on one or a few modes.
      </p>
      <p>
        <small style={{ color: 'gray' }}>
          Full training animation is available under the <b>Full Video</b> tab
          at the beginning of this post
        </small>
      </p>
    </div>
  )
}

const TextBlock6 = (props) => {
  const { classes } = props
  return (
    <div style={{ height: '400px', color: 'black' }}>
      <h3>The Generator Rises</h3>
      <p>
        The Generator survives{' '}
        <b>
          <a
            href="https://jonathan-hui.medium.com/gan-why-it-is-so-hard-to-train-generative-advisory-networks-819a86b3750b"
            target="_blank"
            rel="noopener noreferrer"
          >
            mode collapse
          </a>
        </b>
      </p>
      <p>
        Over time, the Generator learns to get better. The Discriminator becomes
        less sure whether the given image is real or fake
      </p>
      <p>
        In the end, our Generator emerges victorious. It has learned to fool the
        Discriminator. The Discriminator is no longer sure whether the digit is
        real or fake.
      </p>
      <p>
        The <span className={classes.generatorTitle}>generated digits</span>{' '}
        start to fall under the same cluster as the{' '}
        <span className={classes.discriminatorTitle}>real digits</span>. For
        example, the ones (1s) from MNIST handwritten dataset and generated
        images are now part of the same cluster.
      </p>
    </div>
  )
}

const TextBlock7 = () => {
  return (
    <div style={{ height: '400px', color: 'black' }}>
      <h3>From Noise to Digits</h3>
      <p>The Generator created these digits.</p>
      <p>
        <p>
          Considering it had to start from the random noise, it has done a good
          job here. However, there is still room for improvement. For example,
          we see that some images are not very clear or are incomplete. The
          network has trouble generating zeros, threes, and eights.
        </p>
        <i>You can get better results with DCGAN or Wasserstein GAN.</i>
      </p>
    </div>
  )
}

const TextBlock8 = () => {
  return (
    <div style={{ height: '100px', color: 'black' }}>
      <h3>The Next Steps</h3>
      <p></p>
    </div>
  )
}

const NextStepsInfo = () => {
  return (
    <div style={{ height: '300px', color: 'black' }}>
      <p style={{ color: 'black', fontSize: 18, textAlign: 'left' }}>
        {' '}
        Here are some resources to explore further
      </p>
      <ul>
        <li>
          Read the Papers: List of Influential Papers on Semantic Scholar [
          <a
            href="https://www.semanticscholar.org/topic/Generative-adversarial-networks/258908"
            target="_blank"
            rel="noopener noreferrer"
          >
            LINK
          </a>
          ]
        </li>
        <li>
          Take a Course: GAN Specialization on Coursera [
          <a
            href="https://www.coursera.org/specializations/generative-adversarial-networks-gans"
            target="_blank"
            rel="noopener noreferrer"
          >
            LINK
          </a>
          ]
        </li>
        <li>
          Review Code: List of Papers and Resources on GANs [
          <a
            href="https://github.com/nightrome/really-awesome-gan"
            target="_blank"
            rel="noopener noreferrer"
          >
            GITHUB
          </a>
          ]
        </li>
      </ul>
    </div>
  )
}

const StepSwitch = (props) => {
  const { stepIndex, classes } = props
  switch (stepIndex) {
    case 1: {
      return <TextBlock1 classes={classes} />
    }
    case 2: {
      return <TextBlock2 classes={classes} />
    }
    case 3: {
      return <TextBlock3 classes={classes} />
    }
    case 4: {
      return <TextBlock4 classes={classes} />
    }
    case 5: {
      return <TextBlock5 classes={classes} />
    }
    case 6: {
      return <TextBlock6 classes={classes} />
    }
    case 7: {
      return <TextBlock7 classes={classes} />
    }
    case 8: {
      return <TextBlock8 classes={classes} />
    }

    default: {
      return <div style={{ height: '100px' }} />
    }
  }
}

function GANimationScroller(props) {
  const {
    classes,
    realDigits,
    ganModel,
    realCluster5k,
    trainingBegins,
    modeCollapseDanger,
    trainingComplete,
    generatedImages
  } = props

  const [currentStepIndex, setCurrentStepIndex] = useState(-1)

  // This callback fires when a Step hits the offset threshold. It receives the
  // data prop of the step, which in this demo stores the index of the step.
  const onStepEnter = ({ data }) => {
    setCurrentStepIndex(data)
  }

  console.log(props)

  return (
    <div style={{ margin: '10vh 0' }}>
      {/* <div style={{ position: 'sticky', top: 0, border: '1px solid orchid' }}>
        I'm sticky. The current triggered step index is: {currentStepIndex}
      </div> */}
      {/* <figure style={{ height:"500px", position: 'sticky', top: '50px', border: '1px solid orchid', backgroundColor: "orange" }}>
        I'm sticky. The current triggered step index is: {currentStepIndex}
      </figure>       */}
      <div className={classes.graphicContainer}>
        <div className={classes.scroller}>
          <Scrollama onStepEnter={onStepEnter} offset={0.4}>
            {[1, 2, 3, 4, 5, 6, 7, 8].map((item, stepIndex) => (
              <Step data={stepIndex} key={stepIndex}>
                <div
                  style={{
                    margin: '40vh 0',
                    // border: '1px solid gray',
                    opacity: currentStepIndex === stepIndex ? 1 : 0.2
                  }}
                >
                  {/* I'm a Scrollama Step of index {stepIndex} */}
                  <StepSwitch stepIndex={item} classes={classes} />
                </div>
              </Step>
            ))}
          </Scrollama>
        </div>
        <div className={classes.graphic}>
          {/* <div>
            I'm sticky. The current triggered step index is: {currentStepIndex}
          </div> */}
          <div style={{ width: '100%' }}>
            {currentStepIndex == -1 && (
              <Img
                fluid={realDigits.childImageSharp.fluid}
                style={{ maxWidth: 500 }}
              />
            )}
            {currentStepIndex === 0 && (
              <Img
                fluid={realDigits.childImageSharp.fluid}
                style={{ maxWidth: 500 }}
              />
            )}
            {currentStepIndex === 1 && (
              <Img fluid={realCluster5k.childImageSharp.fluid} />
            )}
            {currentStepIndex === 2 && (
              <Img sizes={ganModel.childImageSharp.fluid} />
            )}
            {currentStepIndex === 3 && (
              <Img sizes={trainingBegins.childImageSharp.fluid} />
            )}
            {currentStepIndex === 4 && (
              // <Img sizes={modeCollapseDanger.childImageSharp.fluid} />
              <img src={ShakyGround} />
            )}
            {currentStepIndex === 5 && (
              // <Img sizes={trainingComplete.childImageSharp.fluid} />
              <img src={TrainingSuccess} />
            )}
            {currentStepIndex === 6 && (
              <Img sizes={generatedImages.childImageSharp.fluid} />
            )}
            {currentStepIndex === 7 && <NextStepsInfo />}
          </div>
        </div>
      </div>
    </div>
  )
}

export default injectSheet(styles)(GANimationScroller)
